import answers from "./answers";
import expiryDates from "./expiry-dates";
import nextExpiringItem from "./next-expiring-item";
import pullTightSealNumber from "./pull-tight-seal-number";
import requestStatus from "./request-status";
import submittedBy from "./submitted-by";
import comments from "./comments";

export default function reducer(state, action) {
  return {
    answers: answers(state.answers, action),
    expiryDates: expiryDates(state.expiryDates, action),
    nextExpiringItem: nextExpiringItem(state.nextExpiringItem, action),
    pullTightSealNumber: pullTightSealNumber(state.pullTightSealNumber, action),
    requestStatus: requestStatus(state.requestStatus, action),
    submittedBy: submittedBy(state.submittedBy, action),
    comments: comments(state.comments, action),
  };
}
