const Select = ({
  name,
  value,
  label,
  options,
  required = false,
  onChange,
  className = "",
  blankOptionText = "",
}) => (
  <div className={className}>
    {label && (
      <label
        htmlFor={name}
        className="block text-sm font-medium text-wild-blue-yonder uppercase"
      >
        {label}
      </label>
    )}
    <select
      name={name}
      className="mt-1 block w-full rounded-md py-2 pl-3 pr-10 border-mystic shadow-sm focus:border-wild-blue-yonder focus:ring-wild-blue-yonder"
      required={required}
      onChange={onChange}
      value={value}
    >
      <option value="">{blankOptionText}</option>
      {options.map((option, index) => (
        <option key={`option-${index}`} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  </div>
);

export default Select;
