import { useEffect, useState } from "react";
import Checklist from "../components/checklist-steps/checklist";
import ChecklistSelect from "../components/checklist-steps/checklist-select";
import TrolleyResults from "../components/checklist-steps/trolley-results";
import TrolleySearch from "../components/checklist-steps/trolley-search";

export const ChecklistPage = () => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const stepComponent = () => {
    switch (step) {
      default:
      case 0:
        return <TrolleySearch onNext={() => setStep(1)} />;
      case 1:
        return (
          <TrolleyResults onBack={() => setStep(0)} onNext={() => setStep(2)} />
        );
      case 2:
        return (
          <ChecklistSelect
            onBack={() => setStep(1)}
            onNext={() => setStep(3)}
          />
        );
      case 3:
        return (
          <Checklist onDiscard={() => setStep(0)} onSubmit={() => setStep(0)} />
        );
    }
  };

  return stepComponent();
};
