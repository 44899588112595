import { useContext } from "react";
import { StateContext } from "../state";
import * as API from "../utils/api";
import PageTitle from "../components/page-title";
import Layout from "../components/layout/layout";
import Input from "../components/inputs/input";
import Button from "../components/button";
import * as Toast from "../utils/toasts";
import { useState } from "react";

export const DeviceRegistrationPage = () => {
  const { state, dispatch } = useContext(StateContext);
  const { requestStatus } = state.deviceRegistration;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nhsId, setNhsId] = useState("");
  const [passCode, setPassCode] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({ type: "DEVICE_REGISTRATION_REQUESTED" });

    API.registerDevice(firstName, lastName, nhsId, passCode)
      .then((response) => {
        dispatch({
          type: "DEVICE_REGISTRATION_SUCCESS",
          value: response.data.deviceUuid,
        });
        Toast.success("Device registered");
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "DEVICE_REGISTRATION_FAILED",
        });
        Toast.error("Failed to register device");
      });
  };

  return (
    <Layout>
      <PageTitle title="Please register your device" />
      <form onSubmit={handleSubmit} className="flex flex-col grow space-y-6">
        <Input
          label="First name"
          onChange={(event) => setFirstName(event.target.value)}
          value={firstName}
          disabled={requestStatus === "LOADING"}
          required
        />
        <Input
          label="Last name"
          onChange={(event) => setLastName(event.target.value)}
          value={lastName}
          disabled={requestStatus === "LOADING"}
          required
        />
        <Input
          label="NHS ID"
          onChange={(event) => setNhsId(event.target.value)}
          value={nhsId}
          disabled={requestStatus === "LOADING"}
          required
        />
        <Input
          label="PassCode"
          onChange={(event) => setPassCode(event.target.value)}
          value={passCode}
          disabled={requestStatus === "LOADING"}
          required
        />
        <Button
          label="Register device"
          type="submit"
          loading={requestStatus === "LOADING"}
          disabled={requestStatus === "LOADING"}
        />
      </form>
    </Layout>
  );
};
