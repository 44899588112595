import { useState } from "react";

const NumberInput = ({ value, required = false, onChange }) => {
  const [localValue, setLocalValue] = useState(value || "");

  const increment = () => {
    if (localValue === "") {
      setLocalValue(1);
      onChange(1);
      return;
    }

    setLocalValue(localValue + 1);
    onChange(localValue + 1);
  };

  const decrement = () => {
    if (localValue === "" || localValue === 0) {
      setLocalValue(0);
      onChange(0);
      return;
    }

    setLocalValue(localValue - 1);
    onChange(localValue - 1);
  };

  const handleChange = (event) => {
    if (event.target.value === "") {
      setLocalValue("");
      onChange(null);
      return;
    }

    setLocalValue(parseInt(event.target.value));
    onChange(parseInt(event.target.value));
  };

  return (
    <div className="flex flex-row items-center">
      <button
        type="button"
        onClick={() => decrement()}
        className={`${
          value === 0
            ? "bg-mystic border-mystic text-wild-blue-yonder"
            : "bg-connect-blue-700 border-connect-blue-700 text-white"
        } rounded-full p-1 border-2 flex flex-row justify-center`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
        </svg>
      </button>
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]+"
        className="p-0 text-center w-10 appearance-none border-none bg-transparent focus:border-wild-blue-yonder focus:ring-wild-blue-yonder font-bold text-xl text-black-russian"
        onChange={handleChange}
        required={required}
        value={localValue}
        placeholder="0"
      />
      <button
        type="button"
        onClick={() => increment()}
        className="rounded-full bg-connect-blue-700 p-1 border-2 border-connect-blue-700 text-white flex flex-row justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </button>
    </div>
  );
};

export default NumberInput;
