import React from "react";

const Logo = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 144 140"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" transform="translate(-135 -49)">
        <g transform="translate(135.938 49)">
          <path
            fill="#FFF"
            d="M22.7 96.305l-5.268-10.02h-7.026v10.02H.95V60.09h16.274c9.608 0 15.084 4.648 15.084 12.707 0 5.477-2.273 9.505-6.457 11.674l7.542 11.83H22.7v.004zm-12.294-17.56h6.87c3.823 0 6.045-1.962 6.045-5.629s-2.222-5.476-6.045-5.476h-6.87v11.105zM62.215 84.783H42.79c.877 3.151 3.203 4.909 6.354 4.909 2.378 0 4.7-.981 6.561-2.843l4.805 4.804c-2.843 3.203-7.026 4.96-12.191 4.96-9.144 0-14.773-5.629-14.773-14.155 0-8.527 5.837-14.413 14.568-14.413 9.761.004 14.721 6.562 14.1 16.738zm-8.884-4.908c0-3.203-2.066-5.372-5.165-5.372-3.098 0-4.96 2.17-5.58 5.372H53.33z"
          ></path>
          <path
            fill="#FFF"
            d="M74.198 74.35c-1.55 0-2.635.569-2.635 1.757 0 3.668 14.673 1.91 14.62 11.623 0 5.733-5.011 8.988-11.83 8.988-4.7 0-9.195-1.342-12.606-3.98l2.894-5.94c3.203 2.221 6.818 3.458 9.917 3.458 1.705 0 2.946-.568 2.946-1.705 0-3.823-14.412-1.91-14.412-11.57 0-5.837 4.908-9.04 11.47-9.04 4.03 0 8.11 1.137 11.622 3.255l-3.1 5.993c-3.462-1.806-6.613-2.839-8.886-2.839zM115.882 68.357v27.948h-9.196v-4.544c-1.91 3.255-4.96 4.908-8.936 4.96-6.045 0-9.972-4.184-9.972-10.693V68.36h9.196v15.033c0 3.151 1.757 5.065 4.496 5.065 3.254-.052 5.216-2.635 5.216-6.302V68.364h9.196v-.007zM130.139 74.35c-1.55 0-2.635.569-2.635 1.757 0 3.668 14.673 1.91 14.62 11.623 0 5.733-5.011 8.988-11.83 8.988-4.7 0-9.195-1.342-12.606-3.98l2.894-5.94c3.203 2.221 6.818 3.458 9.917 3.458 1.706 0 2.943-.568 2.943-1.705 0-3.823-14.413-1.91-14.413-11.57 0-5.837 4.909-9.04 11.47-9.04 4.028 0 8.111 1.137 11.622 3.255l-3.098 5.993c-3.46-1.806-6.614-2.839-8.884-2.839z"
          ></path>
          <path
            fill="#00B0BC"
            d="M11.13 104.69c-4.804 0-8.666 3.74-8.666 8.45 0 4.711 3.862 8.482 8.666 8.482 2.34 0 4.62-.974 6.291-2.554l1.49 1.611c-2.096 1.976-4.956 3.224-7.902 3.224-6.142 0-11.006-4.742-11.006-10.763 0-6.02 4.926-10.669 11.096-10.669 2.918 0 5.747 1.186 7.781 3.1l-1.459 1.763c-1.64-1.642-3.951-2.644-6.291-2.644z"
          ></path>
          <path
            fill="#00B0BC"
            d="M42.076 113.172c0 5.99-4.925 10.73-11.126 10.73s-11.158-4.74-11.158-10.73 4.926-10.7 11.158-10.7c6.2-.004 11.126 4.71 11.126 10.7zm-19.82 0c0 4.71 3.952 8.513 8.694 8.513 4.742 0 8.634-3.8 8.634-8.513 0-4.714-3.892-8.451-8.634-8.451-4.742-.003-8.694 3.737-8.694 8.45z"
          ></path>
          <path
            fill="#00B0BC"
            d="M47.5493934 102.530329L60.3188908 119.82669 60.3188908 102.530329 62.7209705 102.530329 62.7209705 123.809359 60.2287695 123.809359 47.4592721 106.540728 47.4592721 123.809359 45.0259965 123.809359 45.0259965 102.530329z"
          ></path>
          <path
            fill="#00B0BC"
            d="M70.2287695 102.530329L82.9982669 119.82669 82.9982669 102.530329 85.4003466 102.530329 85.4003466 123.809359 82.9081456 123.809359 70.1386482 106.540728 70.1386482 123.809359 67.7053726 123.809359 67.7053726 102.530329z"
          ></path>
          <path
            fill="#00B0BC"
            d="M104.824957 102.530329L104.824957 104.779896 92.8180243 104.779896 92.8180243 111.923744 103.549393 111.923744 103.549393 114.142114 92.8180243 114.142114 92.8180243 121.559792 105.220104 121.559792 105.220104 123.809359 90.3847487 123.809359 90.3847487 102.530329z"
          ></path>
          <path
            fill="#00B0BC"
            d="M117.806 104.69c-4.804 0-8.662 3.74-8.662 8.45 0 4.711 3.861 8.482 8.662 8.482 2.34 0 4.62-.974 6.291-2.554l1.49 1.611c-2.097 1.976-4.953 3.224-7.902 3.224-6.14 0-11.006-4.742-11.006-10.763 0-6.02 4.926-10.669 11.096-10.669 2.918 0 5.743 1.186 7.781 3.1l-1.459 1.763c-1.64-1.642-3.951-2.644-6.291-2.644z"
          ></path>
          <path
            fill="#00B0BC"
            d="M141.913345 102.530329L141.913345 104.779896 134.953206 104.779896 134.953206 123.809359 132.519931 123.809359 132.519931 104.779896 125.587522 104.779896 125.587522 102.530329 141.913345 102.530329z"
          ></path>
          <path
            fill="#00B0BC"
            d="M72.8 12.714a4.278 4.278 0 01-2.814-1.068 12.63 12.63 0 00-8.374-3.168c-7.026 0-12.721 5.699-12.721 12.721 0 7.026 5.695 12.718 12.72 12.718h16.957a4.239 4.239 0 110 8.478H61.612c-11.706 0-21.196-9.49-21.196-21.196C40.416 9.494 49.906.007 61.612 0a21.105 21.105 0 0113.99 5.279c2.939 2.586 1.112 7.435-2.801 7.435z"
          ></path>
          <path
            fill="#FFF"
            d="M66.76 46.839c1.035 0 2.034.385 2.81 1.067a12.64 12.64 0 008.375 3.169c7.043 0 12.748-5.737 12.717-12.784-.035-7.022-5.834-12.658-12.856-12.658H60.988c-2.34 0-4.24-1.9-4.24-4.24 0-2.34 1.9-4.239 4.24-4.239h16.96c11.702 0 21.196 9.49 21.196 21.2 0 11.708-9.494 21.192-21.196 21.199a21.14 21.14 0 01-13.986-5.276c-2.943-2.589-1.116-7.438 2.797-7.438z"
          ></path>
          <g fill="#FFF" transform="translate(0 130.374)">
            <path d="M.003.16h3.723c.738 0 1.286.03 1.647.09.36.062.686.19.97.384.288.194.524.454.714.78.19.323.288.687.288 1.089 0 .436-.118.835-.354 1.199a2.178 2.178 0 01-.956.818c.568.166 1.001.447 1.306.846.305.398.458.866.458 1.403 0 .423-.097.836-.295 1.234a2.48 2.48 0 01-.807.957c-.34.24-.763.388-1.262.44-.312.035-1.068.056-2.267.063H0V.159h.003zm1.88 1.549V3.86h1.23c.731 0 1.189-.01 1.365-.03.323-.039.576-.15.76-.334.183-.183.277-.426.277-.728 0-.301-.08-.523-.24-.7-.159-.18-.395-.287-.707-.325-.187-.021-.72-.032-1.604-.032H1.882V1.71zm0 3.698v2.489h1.74c.675 0 1.105-.017 1.289-.055.28-.052.506-.174.682-.371.177-.198.264-.461.264-.79 0-.281-.066-.517-.205-.711a1.168 1.168 0 00-.585-.427c-.253-.09-.812-.135-1.668-.135H1.882z"></path>
            <path d="M13.1334489 9.46967071L13.1334489 5.55285962 9.72270364 0.159445407 11.9272097 0.159445407 14.117851 3.8440208 16.2668977 0.159445407 18.4332756 0.159445407 15.0086655 5.56325823 15.0086655 9.46967071 13.1334489 9.46967071z"></path>
            <path d="M33.255 9.47H31.21l-.811-2.115h-3.723l-.77 2.115h-1.993L27.54.16h1.986l3.73 9.31zm-3.46-3.685L28.513 2.33l-1.258 3.456h2.54z"></path>
            <path d="M39.886 6.045V4.478h4.052v3.71c-.392.38-.964.717-1.713 1.004a6.283 6.283 0 01-2.27.434c-.974 0-1.823-.205-2.548-.614a3.886 3.886 0 01-1.632-1.754 5.666 5.666 0 01-.548-2.478c0-.97.205-1.83.61-2.582A4.089 4.089 0 0137.622.464C38.218.156 38.96 0 39.851 0c1.154 0 2.059.243 2.707.728.648.485 1.068 1.154 1.255 2.01l-1.868.35a1.992 1.992 0 00-.739-1.085c-.364-.263-.814-.395-1.355-.395-.822 0-1.473.26-1.958.78-.486.524-.728 1.293-.728 2.32 0 1.105.246 1.933.738 2.484.489.552 1.133.829 1.93.829.392 0 .787-.076 1.186-.232a4.184 4.184 0 001.02-.562V6.045h-2.153z"></path>
            <path d="M46.9116118 9.46967071L46.9116118 0.159445407 48.7902946 0.159445407 48.7902946 9.46967071z"></path>
            <path d="M51.9341421 9.46967071L51.9341421 0.235701906 53.812825 0.235701906 53.812825 7.89948007 58.4852686 7.89948007 58.4852686 9.46967071 51.9341421 9.46967071z"></path>
            <path d="M61.1265165 9.46967071L61.1265165 0.159445407 68.0311958 0.159445407 68.0311958 1.73310225 63.0086655 1.73310225 63.0086655 3.79896014 67.6811092 3.79896014 67.6811092 5.36568458 63.0086655 5.36568458 63.0086655 7.89948007 68.2114385 7.89948007 68.2114385 9.46967071 61.1265165 9.46967071z"></path>
            <path d="M75.9896014 9.46967071L75.9896014 0.159445407 78.8041594 0.159445407 80.492201 6.50953206 82.1629116 0.159445407 84.9809359 0.159445407 84.9809359 9.46967071 83.2339688 9.46967071 83.2339688 2.14211438 81.3864818 9.46967071 79.5771231 9.46967071 77.7365685 2.14211438 77.7365685 9.46967071z"></path>
            <path d="M88.1490468 9.46967071L88.1490468 0.159445407 95.0537262 0.159445407 95.0537262 1.73310225 90.0311958 1.73310225 90.0311958 3.79896014 94.7036395 3.79896014 94.7036395 5.36568458 90.0311958 5.36568458 90.0311958 7.89948007 95.2339688 7.89948007 95.2339688 9.46967071 88.1490468 9.46967071z"></path>
            <path d="M98.118.16h3.435c.776 0 1.366.058 1.771.176a3.13 3.13 0 011.404.856c.388.413.686.915.887 1.508.205.596.305 1.328.305 2.201 0 .766-.093 1.428-.284 1.983-.232.68-.565 1.227-.995 1.646-.326.32-.766.565-1.32.742-.416.132-.97.198-1.664.198H98.12V.16h-.003zm1.879 1.577v6.166h1.403c.527 0 .905-.028 1.137-.087.305-.076.558-.204.76-.388.2-.18.363-.482.492-.898.128-.416.19-.984.19-1.705 0-.721-.066-1.272-.19-1.657-.129-.385-.305-.686-.534-.901a1.787 1.787 0 00-.87-.437c-.264-.059-.777-.09-1.543-.09h-.845v-.003z"></path>
            <path d="M108.759099 9.46967071L108.759099 0.159445407 110.637782 0.159445407 110.637782 9.46967071z"></path>
            <path d="M119.688 6.045l1.823.579c-.28 1.016-.745 1.771-1.393 2.263-.648.493-1.473.739-2.475.739-1.237 0-2.253-.423-3.047-1.266-.794-.845-1.192-2-1.192-3.462 0-1.55.402-2.752 1.2-3.609.8-.856 1.85-1.286 3.157-1.286 1.137 0 2.062.337 2.773 1.01.423.398.742.97.953 1.715l-1.861.444c-.111-.482-.34-.864-.69-1.144-.35-.281-.773-.42-1.272-.42-.69 0-1.248.246-1.678.742-.43.496-.645 1.296-.645 2.406 0 1.178.212 2.013.635 2.513.423.499.974.748 1.65.748.499 0 .929-.16 1.29-.478.356-.312.616-.811.772-1.494z"></path>
            <path d="M132.818 9.47h-2.045l-.811-2.115h-3.72l-.769 2.115h-1.993l3.626-9.31h1.986l3.726 9.31zm-3.463-3.685l-1.282-3.456-1.258 3.456h2.54z"></path>
            <path d="M135.164645 9.46967071L135.164645 0.235701906 137.043328 0.235701906 137.043328 7.89948007 141.715771 7.89948007 141.715771 9.46967071 135.164645 9.46967071z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
