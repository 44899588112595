export default function reducer(requestStatus, action) {
  switch (action.type) {
    case "DEVICE_REGISTRATION_REQUESTED":
      return "LOADING";
    case "DEVICE_REGISTRATION_SUCCESS":
      return "SUCCESS";
    case "DEVICE_REGISTRATION_FAILED":
      return "ERROR";
    default:
      return requestStatus;
  }
}
