import { useContext } from "react";
import { StateContext } from "../../state";
import Layout from "../layout/layout";
import PageTitle from "../page-title";
import TrolleyDetails from "../trolley-details";
import Button from "../button";
import Input from "../inputs/input";

const TrolleyResults = ({ onNext, onBack }) => {
  const { state, dispatch } = useContext(StateContext);
  const { results, pullTightSealNumber, selectedTrolley } = state.trolley;

  const showPullTightSeal = () => {
    return (
      selectedTrolley &&
      selectedTrolley.capturePullTightSealNumber &&
      selectedTrolley.lastPullTightSealNumber
    );
  };

  const showViewChecklistsButton = () => selectedTrolley;

  const handleSubmit = (event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <Layout>
      <PageTitle title="Confirm the trolley you want to check" />

      <form onSubmit={handleSubmit} className="flex flex-col grow">
        <div className="mb-6 flex flex-col space-y-4">
          {results.map((trolley, index) => {
            return (
              <TrolleyDetails
                key={`trolley-${index}`}
                name={trolley.name}
                type={trolley.type}
                location={trolley.location.name}
                hospitalName={trolley.location.hospital.name}
                lastCheckDate={trolley.lastCheckDate}
                onClick={() =>
                  dispatch({ type: "TROLLEY_SELECTED", value: trolley })
                }
                selected={selectedTrolley && selectedTrolley.id === trolley.id}
              />
            );
          })}
        </div>

        {showPullTightSeal() && (
          <Input
            label="Pull tight seal number"
            value={pullTightSealNumber}
            onChange={(event) =>
              dispatch({
                type: "PULL_TIGHT_SEAL_INPUT",
                value: event.target.value,
              })
            }
            required
            className="mb-6"
          />
        )}
        {showViewChecklistsButton() && (
          <Button
            label="View checklists"
            type="submit"
            className="w-full mb-4"
          />
        )}

        <div className="flex flex-col items-center grow justify-end">
          <Button
            type="button"
            label="Go back"
            onClick={onBack}
            theme="light"
            icon="back"
          />
        </div>
      </form>
    </Layout>
  );
};

export default TrolleyResults;
