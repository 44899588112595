import { useContext } from "react";
import { StateContext } from "../../state";
import * as API from "../../utils/api";
import Button from "../button";
import Input from "../inputs/input";
import DarkLayout from "../layout/dark-layout";
import * as Toast from "../../utils/toasts";

const TrolleySearch = ({ onNext }) => {
  const { state, dispatch } = useContext(StateContext);
  const { trolleyName, requestStatus } = state.trolley;

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({ type: "TROLLEY_REQUESTED" });

    API.searchTrolleys(trolleyName)
      .then((response) => {
        dispatch({ type: "TROLLEY_REQUEST_SUCCESS", value: response.data });

        const trolleys = response.data;

        if (trolleys.length > 0) {
          onNext();
          return;
        }

        Toast.info("No trolleys found");
      })
      .catch((error) => {
        console.log(error);

        dispatch({ type: "TROLLEY_REQUEST_FAILED" });

        Toast.error("An error occurred");
      });
  };

  return (
    <DarkLayout>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col grow justify-end pb-12"
      >
        <Input
          label="Trolley Name"
          onChange={(event) =>
            dispatch({ type: "TROLLEY_NAME_INPUT", value: event.target.value })
          }
          className="mb-6"
          required={true}
          value={trolleyName}
          disabled={requestStatus === "LOADING"}
        />
        <Button
          type="submit"
          label="Search"
          loading={requestStatus === "LOADING"}
          disabled={requestStatus === "LOADING"}
        />
      </form>
    </DarkLayout>
  );
};

export default TrolleySearch;
