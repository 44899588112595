import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  function (config) {
    config.headers["auth"] = JSON.parse(
      window.localStorage.getItem("device_uuid")
    );
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      console.log("Unauthorised");
      window.localStorage.removeItem("device_uuid");
    }

    return Promise.reject(error);
  }
);

export const registerDevice = (firstName, lastName, nhsId, passCode) =>
  api.post("/devices", { firstName, lastName, nhsId, passCode });

export const searchTrolleys = (trolleyName) =>
  api.get(`/trolleys/search?q=${trolleyName}`);

export const getChecklist = (checklistId) =>
  api.get(`/checklists/${checklistId}`);

export const submitChecklist = (
  trolleyId,
  type,
  questions,
  answers,
  expiryDates,
  nextExpiringItem,
  pullTightSealNumber,
  submittedBy,
  comments
) =>
  api.post(
    `trolleys/${trolleyId}/completed-checklists`,
    buildCompletedChecklistsPayload(
      type,
      questions,
      answers,
      expiryDates,
      nextExpiringItem,
      pullTightSealNumber,
      submittedBy,
      comments
    ),
    {
      headers: {
        "Content-Type": "application/json",
        auth: JSON.parse(window.localStorage.getItem("device_uuid")),
      },
    }
  );

const buildCompletedChecklistsPayload = (
  type,
  questions,
  answers,
  expiryDates,
  nextExpiringItem,
  pullTightSealNumber,
  submittedBy,
  comments
) => {
  const items = questions.map((question) => {
    return {
      id: question.id,
      title: question.text,
      answer: {
        expected:
          question.type === "quantity"
            ? question.expectedQuantity
            : question.expectedBoolean,
        actual: answers[question.id],
      },
      expiryDate: expiryDates[question.id],
    };
  });
  console.log(comments)

  return {
    type,
    items,
    nextExpiringItem,
    pullTightSealNumber,
    submittedBy,
    comments,
  };
};
