import Logo from "../logos/logo";

const DarkLayout = ({ children }) => (
  <div className="bg-connect-blue-900 h-full flex flex-col">
    <header className="h-auto pt-12 bg-connect-blue-900 flex flex-row items-center justify-center">
      <Logo height={144} width={140} />
    </header>
    <main className="container mx-auto max-w-xl flex grow px-2">
      {children}
    </main>
  </div>
);
export default DarkLayout;
