export default function reducer(result, action) {
  switch (action.type) {
    case "CHECKLIST_REQUEST_SUCCESS":
      return action.value;
    case "CHECKLIST_REQUESTED":
      return null;
    default:
      return result;
  }
}
