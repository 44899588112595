export default function reducer(requestStatus, action) {
  switch (action.type) {
    case "COMPLETE_SUBMISSION_REQUESTED":
      return "LOADING";
    case "COMPLETE_SUBMISSION_REQUEST_SUCCESS":
      return "SUCCESS";
    case "COMPLETE_SUBMISSION_REQUEST_FAILED":
      return "ERROR";
    default:
      return requestStatus;
  }
}
