import { useContext, useEffect } from "react";
import { StateContext } from "../../state";
import ChecklistQuestion from "../checklist-question";
import * as API from "../../utils/api";
import Layout from "../layout/layout";
import PageTitle from "../page-title";
import Button from "../button";
import * as Toast from "../../utils/toasts";
import Input from "../inputs/input";
import TextArea from "../inputs/textarea";
import Select from "../inputs/select";
import Loading from "../loading";

const Checklist = ({ onDiscard, onSubmit }) => {
  const { state, dispatch } = useContext(StateContext);
  const { selectedChecklist } = state.trolley;
  const { requestStatus, result: checklist } = state.checklist;
  const { selectedTrolley } = state.trolley;
  const {
    answers,
    expiryDates,
    nextExpiringItem,
    pullTightSealNumber,
    submittedBy,
    comments,
  } = state.submission;

  useEffect(() => {
    dispatch({ type: "CHECKLIST_REQUESTED" });

    API.getChecklist(selectedChecklist)
      .then((response) =>
        dispatch({ type: "CHECKLIST_REQUEST_SUCCESS", value: response.data }),
      )
      .catch((error) => {
        console.log(error);
        dispatch({ type: "CHECKLIST_REQUEST_ERROR" });
      });
  }, [selectedChecklist]);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({ type: "COMPLETE_SUBMISSION_REQUESTED" });

    API.submitChecklist(
      selectedTrolley.id,
      checklist.type,
      checklist.checklistQuestions,
      answers,
      expiryDates,
      nextExpiringItem,
      pullTightSealNumber,
      submittedBy,
      comments,
    )
      .then(() => {
        dispatch({ type: "COMPLETE_SUBMISSION_REQUEST_SUCCESS" });
        Toast.success("Checklist submitted");
        onSubmit();
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "COMPLETE_SUBMISSION_REQUEST_ERROR" });
        Toast.error("Failed to complete checklist");
      });
  };

  const currentExpiryDate = (id, productExpirations) => {
    console.log("chekcing");
    if (productExpirations == null) {
      return null;
    }

    return (
      productExpirations.find((expiry) => expiry.id === id)?.expiryDate ?? null
    );
  };

  const handleDiscard = () => {
    const discard = window.confirm(
      "Are you sure you want to discard this checklist? All progress will be lost.",
    );

    if (discard) {
      dispatch({ type: "DISCARD_CHECKLIST" });
      onDiscard();
    }
  };

  return (
    <Layout>
      <PageTitle title={`${selectedTrolley.name} checklist`} />
      {requestStatus === "LOADING" && (
        <div className="flex flex-row justify-center">
          <Loading width="w=10" height="h-10" />
        </div>
      )}
      {requestStatus === "SUCCESS" && (
        <form onSubmit={handleSubmit} className="flex flex-col grow space-y-6">
          {checklist.checklistQuestions.map((checklistQuestion, index) => (
            <ChecklistQuestion
              key={`question-${index}`}
              question={checklistQuestion}
              trolleyExpiryDateMethod={selectedTrolley.expiryDateMethod}
              onAnswerChange={(answer) =>
                dispatch({
                  type: "ANSWER_SELECTED",
                  id: checklistQuestion.id,
                  answer: answer,
                })
              }
              onExpiryDateChange={(expiryDate) =>
                dispatch({
                  type: "EXPIRY_DATE_SELECTED",
                  id: checklistQuestion.id,
                  expiryDate: expiryDate,
                })
              }
              answer={
                answers.hasOwnProperty(checklistQuestion.id)
                  ? answers[checklistQuestion.id]
                  : null
              }
              expiryDate={
                expiryDates[checklistQuestion.id] ||
                currentExpiryDate(
                  checklistQuestion.id,
                  selectedTrolley.productExpirations,
                )
              }
            />
          ))}
          {selectedTrolley.expiryDateMethod === "earliest_item" && (
            <div className="space-y-2">
              <Select
                label="Next expiring item"
                options={checklist.checklistQuestions.map((question) => {
                  return { name: question.text, value: question.id };
                })}
                onChange={(event) =>
                  dispatch({
                    type: "NEXT_EXPIRING_ITEM_SELECTED",
                    id: parseInt(event.target.value),
                    title:
                      event.target.options[event.target.options.selectedIndex]
                        .text,
                  })
                }
                value={
                  nextExpiringItem.hasOwnProperty("id")
                    ? nextExpiringItem.id
                    : ""
                }
                blankOptionText="Select an item"
                required
              />
              <Input
                type="date"
                onChange={(event) =>
                  dispatch({
                    type: "NEXT_EXPIRING_ITEM_DATE_SELECTED",
                    value: event.target.value,
                  })
                }
                value={
                  nextExpiringItem.hasOwnProperty("expiryDate")
                    ? nextExpiringItem.expiryDate
                    : ""
                }
                placeholder="Expiry date"
                required
              />
            </div>
          )}

          {selectedTrolley.capturePullTightSealNumber && (
            <Input
              label="Pull tight seal number"
              onChange={(event) =>
                dispatch({
                  type: "SUBMISSION_PULL_TIGHT_SEAL_NUMBER_INPUT",
                  value: event.target.value,
                })
              }
              value={pullTightSealNumber}
              required
            />
          )}

          <Input
            label="Respondent name"
            onChange={(event) =>
              dispatch({
                type: "SUBMITTED_BY_INPUT",
                value: event.target.value,
              })
            }
            value={submittedBy}
            required
          />
          <TextArea
            label="Comments"
            onChange={(event) =>
              dispatch({
                type: "COMMENTS_INPUT",
                value: event.target.value,
              })
            }
            value={comments}
            required
          />

          <div className="flex flex-col items-center grow justify-end space-y-2">
            <Button
              type="submit"
              label="Complete checklist"
              className="w-full"
              loading={requestStatus === "LOADING"}
              disabled={requestStatus === "LOADING"}
            />
            <Button
              type="button"
              label="Discard"
              onClick={() => handleDiscard()}
              className="w-full"
              theme="warning"
            />
          </div>
        </form>
      )}
    </Layout>
  );
};

export default Checklist;
