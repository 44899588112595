import { useContext, useState } from "react";
import { StateContext } from "../../state";
import Info from "../alerts/info";
import Button from "../button";
import ChecklistDetails from "../checklist-details";
import Layout from "../layout/layout";
import PageTitle from "../page-title";

const ChecklistSelect = ({ onNext, onBack }) => {
  const { state, dispatch } = useContext(StateContext);
  const { selectedTrolley, pullTightSealNumber } = state.trolley;

  /**
   * Using a local state item here over the selected trolley ID as there is
   * potential for daily, full and post emergency checklists to have the same ID.
   */
  const [selectedChecklist, setSelectedChecklist] = useState("");

  const pullTightSealNumberHasChanged = () => {
    return (
      selectedTrolley.capturePullTightSealNumber &&
      pullTightSealNumber !== selectedTrolley.lastPullTightSealNumber
    );
  };

  const hasDailyChecklist = () => selectedTrolley.dailyChecklist;
  const hasFullChecklist = () => selectedTrolley.fullChecklist;
  const hasPostEmergencyChecklist = () =>
    selectedTrolley.postEmergencyChecklist;

  const showStartChecklistButton = () => selectedChecklist;

  return (
    <Layout>
      <PageTitle title="Pick a checklist" />
      {pullTightSealNumberHasChanged() && (
        <Info
          className="mb-6"
          text="Because the pull tight seal number has changed since the last check, you must complete a full check."
        />
      )}
      <div className="mb-6 flex flex-col space-y-4">
        {!pullTightSealNumberHasChanged() && hasDailyChecklist() && (
          <ChecklistDetails
            name="Daily checklist"
            selected={selectedChecklist === "Daily checklist"}
            onClick={() => {
              setSelectedChecklist("Daily checklist");
              dispatch({
                type: "CHECKLIST_SELECTED",
                value: selectedTrolley.dailyChecklist.id,
              });
            }}
          />
        )}
        {hasFullChecklist() && (
          <ChecklistDetails
            name="Full checklist"
            selected={selectedChecklist === "Full checklist"}
            onClick={() => {
              setSelectedChecklist("Full checklist");
              dispatch({
                type: "CHECKLIST_SELECTED",
                value: selectedTrolley.fullChecklist.id,
              });
            }}
          />
        )}
        {!pullTightSealNumberHasChanged() && hasPostEmergencyChecklist() && (
          <ChecklistDetails
            name="Post emergency checklist"
            selected={selectedChecklist === "Post emergency checklist"}
            onClick={() => {
              setSelectedChecklist("Post emergency checklist");
              dispatch({
                type: "CHECKLIST_SELECTED",
                value: selectedTrolley.postEmergencyChecklist.id,
              });
            }}
          />
        )}
      </div>
      {showStartChecklistButton() && (
        <Button
          label="Start checklist"
          type="button"
          className="w-full"
          onClick={onNext}
        />
      )}

      <div className="flex flex-col items-center grow justify-end">
        <Button
          type="button"
          label="Go back"
          onClick={onBack}
          theme="light"
          icon="back"
        />
      </div>
    </Layout>
  );
};

export default ChecklistSelect;
