const Input = ({
  type = "text",
  label,
  name,
  placeholder,
  value,
  onChange,
  required = false,
  disabled = false,
  className,
}) => (
  <div className={`${className} font-roboto`}>
    {label && (
      <label
        htmlFor={name}
        className="block text-sm font-medium text-wild-blue-yonder uppercase"
      >
        {label}
      </label>
    )}
    <div className="mt-1">
      <input
        type={type}
        name={name}
        className={`${
          disabled ? "cursor-not-allowed bg-mystic text-wild-blue-yonder" : ""
        } block w-full rounded-lg border-mystic shadow-sm focus:border-wild-blue-yonder focus:ring-wild-blue-yonder`}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        disabled={disabled}
        value={value}
      />
    </div>
  </div>
);

export default Input;
