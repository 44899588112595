export default function reducer(nextExpiringItem, action) {
  switch (action.type) {
    case "NEXT_EXPIRING_ITEM_SELECTED":
      return {
        ...nextExpiringItem,
        id: action.id,
        title: action.title,
      };
    case "NEXT_EXPIRING_ITEM_DATE_SELECTED":
      return { ...nextExpiringItem, expiryDate: action.value };
    default:
      return nextExpiringItem;
  }
}
