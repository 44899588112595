import { useState } from "react";
import Badge from "./badge";
import BooleanInput from "./inputs/boolean-input";
import Input from "./inputs/input";
import NumberInput from "./inputs/number-input";

const ChecklistQuestion = ({
  question,
  trolleyExpiryDateMethod,
  onAnswerChange,
  onExpiryDateChange,
  answer,
  expiryDate,
}) => {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const toggleAdditionalInfo = () => setShowAdditionalInfo(!showAdditionalInfo);

  const isCompliant = () => {
    console.log("expiryDate", expiryDate);
    const haveAnswer = answer !== null;
    const haveExpiryDate = expiryDate !== null;
    const requiresExpiryDate =
      trolleyExpiryDateMethod === "per_item" && question.captureExpiry;

    if (!haveAnswer || (requiresExpiryDate && !haveExpiryDate)) {
      return null;
    }

    let expiryDateIsCompliant = true;

    if (requiresExpiryDate && new Date(expiryDate) < new Date()) {
      expiryDateIsCompliant = false;
    }

    const answerIsCompliant =
      question.type === "quantity"
        ? answer >= question.expectedQuantity
        : answer === question.expectedBoolean;

    return answerIsCompliant && expiryDateIsCompliant;
  };

  const answerInputComponent = () => {
    if (question.type === "quantity") {
      return <NumberInput onChange={onAnswerChange} value={answer} required />;
    }

    return (
      <BooleanInput
        name={`question-${question.id}-select`}
        onChange={onAnswerChange}
        value={answer}
        required
      />
    );
  };

  const expectedValue = () => {
    if (question.type === "quantity") {
      return question.expectedQuantity;
    }

    return question.expectedBoolean ? "Yes" : "No";
  };

  const complianceClasses = () => {
    switch (isCompliant()) {
      default:
      case null:
        return "bg-transparent";
      case true:
        return "bg-pacific-blue";
      case false:
        return "bg-red-600";
    }
  };

  const expiryDateRequired = () => {
    if (trolleyExpiryDateMethod === "earliest_item") {
      return false;
    }

    return question.captureExpiry;
  };

  return (
    <div className="relative overflow-hidden border-mystic rounded-lg bg-gray-50 border flex flex-row">
      <div
        className={`${complianceClasses()} w-2 h-full transition ease-in-out`}
      />
      <div className="flex flex-col grow">
        <div className="flex flex-row justify-end py-2 px-4">
          <Badge heading="Expected:" text={`${expectedValue()}`} />
        </div>

        <div className="px-4 pb-4 flex flex-col grow space-y-3">
          <div>
            <div className="font-bold text-connect-blue-700">
              {question.text}{" "}
            </div>
            {(question.image || question.info) && (
              <div
                className="text-xs text-wild-blue-yonder cursor-pointer"
                onClick={() => toggleAdditionalInfo()}
              >{`${!showAdditionalInfo ? "show more" : "show less"}`}</div>
            )}
          </div>
          {showAdditionalInfo && (
            <div className="text-black-russian flex flex-col space-y-2">
              <div>{question.info}</div>
              {question.image && <img src={question.image} />}
            </div>
          )}
          <div className="flex flex-row justify-between space-x-5">
            <div className="flex flex-row w-1/2">{answerInputComponent()}</div>
            {expiryDateRequired() && (
              <Input
                type="date"
                className="w-1/2"
                onChange={(event) => onExpiryDateChange(event.target.value)}
                value={expiryDate || ""}
                disabled={!expiryDateRequired()}
                placeholder="Expiry date"
                required
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistQuestion;
