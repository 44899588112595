export default function reducer(pullTightSealNumber, action) {
  switch (action.type) {
    case "PULL_TIGHT_SEAL_INPUT":
      return action.value;
    case "TROLLEY_REQUEST_SUCCESS":
      return "";
    default:
      return pullTightSealNumber;
  }
}
