import SmallLogo from "../logos/small-logo";

const Layout = ({ children }) => (
  <div className="h-full flex flex-col">
    <header className="min-h-14 h-14 bg-connect-blue-700 flex flex-row items-center justify-center">
      <SmallLogo height={30} width={30} />
    </header>
    <main className="flex flex-col grow overflow-y-auto">
      <div className="flex flex-col grow px-2 py-11 container mx-auto max-w-xl">
        {children}
      </div>
    </main>
  </div>
);
export default Layout;
