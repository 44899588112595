const SmallLogo = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 30 30"
  >
    <defs>
      <path
        id="path-_-83ads1u4-1"
        d="M0 0L21.0151117 0 21.0151117 20.8697272 0 20.8697272z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-192 -13)">
        <g transform="translate(192.275 13.25)">
          <path
            fill="#FFF"
            d="M14.999 8.191c2.003.124 4.04.056 6.001.41 5.643 1.014 9.294 6.563 8.281 12.348-.95 5.434-6.418 9.398-11.929 8.395-1.857-.337-3.66-1.226-5.354-2.117-1.227-.646-1.688-1.906-.755-3.169.915-1.236 2.077-1.086 3.283-.302 3.577 2.323 7.486 1.763 9.533-1.352 1.312-1.998 1.445-4.13.396-6.244-1.157-2.33-3.17-3.465-5.74-3.518-2.661-.055-5.325-.032-7.987-.008-1.481.014-2.601-.449-2.767-2.08-.138-1.358.963-2.217 2.771-2.245 1.42-.022 2.84-.004 4.26-.004L15 8.19"
          ></path>
          <g>
            <mask id="mask-_-83ads1u4-2" fill="#fff">
              <use xlinkHref="#path-_-83ads1u4-1"></use>
            </mask>
            <path
              fill="#12AFBC"
              d="M7.809 20.347C2.028 19.054-1.287 12.981.472 7.251 2.188 1.66 8.556-1.446 14.039.665c.987.38 1.957.85 2.85 1.415 1.103.695 1.78 1.707.902 2.975-.828 1.197-1.93 1.054-3.129.411-1.125-.603-2.306-1.32-3.53-1.483C7.43 3.49 4.224 6.14 3.937 9.694c-.315 3.909 2.391 7.034 6.381 7.205 2.657.113 5.322.05 7.982.025 1.366-.012 2.547.224 2.705 1.856.123 1.267-.863 1.924-2.774 1.94-1.36.01-2.72.001-4.08.001l-.01.149c-2.115-.159-4.285-.064-6.332-.523z"
              mask="url(#mask-_-83ads1u4-2)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SmallLogo;
