const TrolleyDetails = ({
  name,
  type,
  location,
  hospitalName,
  lastCheckDate,
  selected,
  onClick,
}) => {
  const daysSinceLastCheckText = () => {
    const today = new Date();
    const lastCheck = new Date(lastCheckDate);
    const daysSinceLastCheck = Math.floor(
      (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) -
        Date.UTC(
          lastCheck.getFullYear(),
          lastCheck.getMonth(),
          lastCheck.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );

    return daysSinceLastCheck === 0
      ? "Today"
      : `${daysSinceLastCheck} day${daysSinceLastCheck > 1 ? "s" : ""} ago`;
  };

  return (
    <div
      onClick={onClick}
      className={`${
        selected ? "border-pacific-blue" : "border-mystic"
      } p-4 bg-gray-50 border rounded-lg cursor-pointer flex flex-col space-y-1`}
    >
      <div className="font-bold text-connect-blue-700 text-2xl">
        {name}{" "}
        <span className="font-bold text-sm text-wild-blue-yonder">{type}</span>
      </div>
      <div className="font-roboto text-black-russian text-lg">
        {hospitalName} ({location})
      </div>

      <div className="font-bold text-sm text-wild-blue-yonder">
        Last checked: {lastCheckDate ? daysSinceLastCheckText() : "Never"}
      </div>
    </div>
  );
};

export default TrolleyDetails;
