import auth from "./reducers/auth";
import checklist from "./reducers/checklist";
import deviceRegistration from "./reducers/device-registration";
import submission from "./reducers/submission";
import trolley from "./reducers/trolley";
import { initialState, initializer } from "./initial-state";

export default function reducer(state, action) {
  if (
    action.type === "DISCARD_CHECKLIST" ||
    action.type === "COMPLETE_SUBMISSION_REQUEST_SUCCESS"
  ) {
    return initializer(initialState);
  }

  const next = {
    auth: auth(state.auth, action),
    deviceRegistration: deviceRegistration(state.deviceRegistration, action),
    trolley: trolley(state.trolley, action),
    checklist: checklist(state.checklist, action),
    submission: submission(state.submission, action),
  };

  if (process.env.NODE_ENV !== "production") {
    console.log({ action, old: state, next });
  }

  return next;
}
