import React, { createContext, useReducer } from "react";
import reducer from "./reducer";
import initialState, { initializer } from "./initial-state";

export const StateContext = createContext();

export function StateProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState, initializer);

  return <StateContext.Provider value={{ state, dispatch }} {...props} />;
}
