import pullTightSealNumber from "./pull-tight-seal-number";
import productExpirations from "./product-expirations";
import requestStatus from "./request-status";
import results from "./results";
import selectedChecklist from "./selected-checklist";
import selectedTrolley from "./selected-trolley";
import trolleyName from "./trolley-name";

export default function reducer(state, action) {
  return {
    pullTightSealNumber: pullTightSealNumber(state.pullTightSealNumber, action),
    requestStatus: requestStatus(state.requestStatus, action),
    results: results(state.results, action),
    selectedChecklist: selectedChecklist(state.selectedChecklist, action),
    selectedTrolley: selectedTrolley(state.selectedTrolley, action),
    trolleyName: trolleyName(state.trolleyName, action),
  };
}
