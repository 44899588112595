import { Routes, Route } from "react-router-dom";
import { DeviceRegistrationPage } from "./pages/device-registration-page";
import { ChecklistPage } from "./pages/checklist-page";
import AuthenticatedRoute from "./components/routing/authenticated-route";
import UnauthenticatedRoute from "./components/routing/unauthenticated-route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/device-registration"
          element={
            <UnauthenticatedRoute>
              <DeviceRegistrationPage />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/"
          element={
            <AuthenticatedRoute>
              <ChecklistPage />
            </AuthenticatedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
