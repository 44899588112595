import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { StateContext } from "../../state";

export const UnauthenticatedRoute = ({ children }) => {
  const { state } = useContext(StateContext);
  const { deviceUuid } = state.auth;

  if (deviceUuid) {
    return <Navigate to="/" />;
  }

  return children;
};

export default UnauthenticatedRoute;
