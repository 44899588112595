export const initialState = {
  auth: {
    deviceUuid: null,
  },
  deviceRegistration: {
    requestStatus: "NOT_ASKED",
  },
  trolley: {
    productExpirations: [],
    pullTightSealNumber: "",
    requestStatus: "NOT_ASKED",
    results: [],
    selectedChecklist: null,
    selectedTrolley: null,
    trolleyName: "",
  },
  checklist: {
    requestStatus: "NOT_ASKED",
    result: null,
  },
  submission: {
    answers: {},
    expiryDates: {},
    nextExpiringItem: {},
    pullTightSealNumber: "",
    requestStatus: "NOT_ASKED",
    submittedBy: "",
  },
};

export const initializer = (initialState) => {
  return {
    ...initialState,
    auth: {
      deviceUuid: JSON.parse(localStorage.getItem("device_uuid")) || null,
    },
  };
};

export default initialState;
