const ChecklistDetails = ({ name, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${
        selected ? "border-pacific-blue" : "border-mystic"
      } p-4 bg-gray-50 border rounded-lg cursor-pointer flex flex-col space-y-1`}
    >
      <div className="font-bold text-connect-blue-700 text-lg">{name}</div>
    </div>
  );
};

export default ChecklistDetails;
