export default function reducer(results, action) {
  switch (action.type) {
    case "TROLLEY_REQUEST_SUCCESS":
      return action.value;
    case "TROLLEY_REQUESTED":
      return null;
    default:
      return results;
  }
}
