export default function reducer(selectedTrolley, action) {
  switch (action.type) {
    case "TROLLEY_SELECTED":
      return action.value;
    case "TROLLEY_REQUEST_SUCCESS":
      return null;
    default:
      return selectedTrolley;
  }
}
