import { toast } from "react-toastify";

const toastConfig = {
  position: "top-right",
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "light",
};

export const error = (message) => toast.error(message, toastConfig);

export const info = (message) => toast.info(message, toastConfig);

export const success = (message) => toast.success(message, toastConfig);
