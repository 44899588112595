const BooleanInput = ({ name, value, required = false, onChange }) => {
  const labelClasses = (active) =>
    `${
      active
        ? "text-white bg-connect-blue-700"
        : "bg-transparent text-connect-blue-700"
    } relative cursor-pointer box-border border-2 border-connect-blue-700 rounded-full flex flex-row justify-center p-1`;

  const inputClasses =
    "h-0 w-0 absolute left-0 bottom-0 focus:ring-0 opacity-0";

  return (
    <div className="flex flex-row items-center space-x-10">
      <label
        className={labelClasses(value === true)}
        onClick={() => onChange(true)}
      >
        <input
          type="radio"
          name={name}
          className={inputClasses}
          required={required}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>
      </label>
      <label
        className={labelClasses(value === false)}
        onClick={() => onChange(false)}
      >
        <input
          type="radio"
          name={name}
          className={inputClasses}
          required={required}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </label>
    </div>
  );
};

export default BooleanInput;
