import Loading from "./loading";

const Button = ({
  type = "button",
  label,
  disabled = false,
  loading = false,
  className = "",
  onClick,
  theme = "dark",
  icon = "",
}) => {
  const themeClasses = () => {
    switch (theme) {
      default:
      case "dark":
        return "bg-connect-blue-700 hover:bg-connect-blue-600 text-white";
      case "light":
        return "bg-periwinkle-gray text-black-russian";
      case "warning":
        return "bg-periwinkle-gray text-red-500";
    }
  };
  return (
    <button
      type={type}
      className={`${
        disabled && "cursor-not-allowed"
      } ${className} inline-flex items-center justify-center rounded-lg border border-transparent p-2.5 text-lg font-medium shadow-sm focus:outline-none ${themeClasses()}`}
      disabled={disabled}
      onClick={onClick}
    >
      {loading && <Loading width="w-5" height="h-5" textColor="text-white" />}
      {!loading && icon === "back" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="3"
          stroke="currentColor"
          className="w-5 h-5 mr-2 text-connect-blue-700"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      )}
      {!loading && label}
    </button>
  );
};

export default Button;
