export default function reducer(selectedChecklist, action) {
  switch (action.type) {
    case "CHECKLIST_SELECTED":
      return action.value;
    case "TROLLEY_REQUEST_SUCCESS":
      return null;
    default:
      return selectedChecklist;
  }
}
